import { useState } from 'react';
import { Account } from '@klever/sdk';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import Button from '@mui/material/Button';

// Hooks
import { useSdk, useDidUpdateEffect } from '../hooks/KleverHook.tsx'

const Buffer = require('buffer/').Buffer  // note: the trailing slash is important!


const WalletButton = () => {
    const sdk = useSdk();

    const [privateKey, setPrivateKey] = useState(
        sessionStorage.getItem('privateKey') || '',
      );
    const [walletAddress, setWalletAddress] = useState(
    sessionStorage.getItem('walletAddress') || '',
    );
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')
    
    
    const importWallet = () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = _this => {
                  let files =   Array.from(input.files);
                  readFile(files);
              };
        input.click();
    }

    const parserPemFile = (file) => {
        const splitter = '-----';
        const maxSplitSize = 5;
    
        if (!file.includes(splitter)) {
          return '';
        }
    
        const contents = file.split(splitter);
        if (contents.length !== maxSplitSize) {
          return '';
        }
    
        return contents;
      };
    
      const getPrivateKey = (contents) => {
        const privateKeyPosition = 2;
    
        if (contents === '') {
          return '';
        }
    
        return contents[privateKeyPosition];
      };
    
      const decodePrivateKey = (longPrivateKey) => {
        const decodedToBase64 = Buffer.from(longPrivateKey, 'base64');
        const decodedToHex = Buffer.from(decodedToBase64.toString(), 'hex');
        const privateKey = decodedToHex.slice(0, 32);
    
        const encodedToHex = [...new Uint8Array(privateKey)]
          .map(byte => byte.toString(16).padStart(2, '0'))
          .join('');
    
        return encodedToHex;
      };
    
      const getWalletAddress = (contents) => {
        const splitter = 'for ';
        const walletAddressPosition = 1;
    
        if (contents === '') {
          return '';
        }
    
        const parsed = contents[walletAddressPosition];
        const walletAddress = parsed.split(splitter)[walletAddressPosition];

        return walletAddress;
      };
    const readFile = (files) => {
        if (files && files.length > 0) {
          const file = files[0];
          const fileExtension = file.name.substring(file.name.length - 3);
          
          if (fileExtension !== 'pem') {
            setError('Invalid file format.');
            return;
          }
          
          const reader = new FileReader();
          reader.onload = (e) => {
            let result = e.target?.result;
            if (typeof result !== 'string') {
              result = '';
            }
    
            const contents = parserPemFile(result);
            if (contents === '') {
              setError('Invalid PEM file.');
              return;
            }
    
            const privateKey = getPrivateKey(contents);
            if (privateKey === '') {
              setError('Invalid Private Key.');
              return;
            }
    
            const walletAddress = getWalletAddress(contents);
            if (walletAddress === '') {
              setError('Invalid Wallet Address.');
              setWalletAddress('');
              return;
            }
    
            setPrivateKey(decodePrivateKey(privateKey));
            setWalletAddress(walletAddress);
            setLoading(true);
            if (error !== '') setError('');
          };
          reader.readAsText(file);
        }
      };

    useDidUpdateEffect(() => {
        const validateAddress = async () => {
            if (walletAddress === '') {
            setError('Invalid Wallet Address.');
            return;
            }
            if (privateKey === '') {
            setError('Invalid Private Key.');
            return;
            }

            const account = new Account(walletAddress, privateKey);
            sdk.setAccount(account);

            setLoading(false);

            if (!walletAddress.includes('klv')) {
            setError('Invalid Wallet Address.');
            setWalletAddress('');
            return;
            }

            sessionStorage.setItem('privateKey', privateKey);
            sessionStorage.setItem('walletAddress', walletAddress);

            //history.push('/wallet');
        };
        validateAddress();
    }, [privateKey, walletAddress]);

  return (
    <>
        <Button 
            variant="contained" 
            startIcon={<AccountBalanceWalletIcon/>} 
            onClick={importWallet}
        >
            {sessionStorage.getItem('walletAddress') ? 
                sessionStorage.getItem('walletAddress').slice(0,5) +'...'+sessionStorage.getItem('walletAddress').slice(-5) : 
                'Connect Wallet'
            }
        </Button>
        <span>{error}</span>
    </>
  )
};

export default WalletButton;