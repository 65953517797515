// Dependencies
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import { Outlet, Link } from "react-router-dom";

// Components
import ConfirmationDialog from './confirmTransDialog';
import CardBalanceDialog from './cardBalanceDialog';

// Hooks
import { useSdk } from '../hooks/KleverHook.tsx'

function shortenWalletAddr(addr, bits) {
    return addr.slice(0, bits) + '...' + addr.slice(-(bits))
}
function splitName (name) {
    const assetId = name.split("/")
    return assetId[0]
 }
const filterAttribute = (items, name) => {
    return items.filter((item) => item.trait_type === name)
 }
const DetailedCardsBuy = ({ data }) => {
    const sdk = useSdk();
    const isLoaded = sdk.isLoaded()
    const account = sdk.getAccount();
    const [message, setMessage] = useState("");
    const [rawTX, setRawTX] = useState(undefined)
    const assets = Object.entries(data)
    const [dialogState, setDialogState] = useState(false);
    const [assetId, setAssetId] = useState("");
    const handleClickBuy = async (info) => {
        console.log(info)
        
        if(isLoaded){
            setMessage("")
            let props = {
                autobroadcast: false,
                previousTX: undefined,
            }
            let payload = {
                buyType: 1,
                id: info.orderId,
                currencyId: "KLV",
                amount: 1000,
            }
            await account.sendBuyOrder(payload, props)
            .then(function (response) {
                // handle success
                console.log(response);
                setRawTX(response);
            
                //setMessage("Success")
            })
            .catch(function (error) {
                // handle error
                console.log(`Error: ${error.message}`);
                setMessage(error.message)
            })
            .then(function () {
                // always executed
            });
        }
    }
    return (
        <>
            {assets.map((nft, index) => {
                //console.log(nft[1].metadata)
                return (
                    <Grow
                        in={true}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(true ? { timeout: 3000 } : {})}
                        key={index}
                    >
                        <Card key={index} sx={{ minWidth: 215, maxWidth: 225, margin: 2 }}>
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    {nft[1].assetName}
                                </Typography>
                            </CardContent>

                            <CardMedia
                                component="img"
                                alt="Ticket Logo"
                                height="115"
                                image={nft[1].metadata ? nft[1].metadata.image : 'https://rudemining.com/klv/logo.png'}
                            />
                            <CardContent>
                                <Typography variant="body1" component="div" color="text.primary">
                                    Price: {nft[1].metadata ? nft[1].metadata.location ? nft[1].metadata.location : "........" : "........"}
                                </Typography>
                                <Typography variant="body2" color="text.primary" component="div">
                                    Section: {nft[1].metadata ? nft[1].metadata.attributes ? filterAttribute(nft[1].metadata.attributes, 'sectionName').value : "........" : "........"}
                                </Typography>
                                <Typography variant="body2" color="text.primary"  component="div">
                                    Row: {nft[1].metadata ? nft[1].metadata.attributes ? filterAttribute(nft[1].metadata.attributes, 'rowName').value : "........" : "........"}
                                </Typography>
                                <Typography variant="body2" color="text.primary" component="div">
                                    Seat: {nft[1].metadata ? nft[1].metadata.attributes ? filterAttribute(nft[1].metadata.attributes, 'seatNum').value : "........" : "........"}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" component="div">
                                    {nft[1].assetId}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={() => handleClickBuy(nft[1])}>Buy Now</Button>
                            </CardActions>
                        </Card>
                    </Grow >
                )
            })}
            <ConfirmationDialog data={rawTX}/>
        </>
    );
}
const DetailedCards = ({ data }) => {
    const assets = Object.entries(data)
    const [dialogState, setDialogState] = useState(false);
    const [assetId, setAssetId] = useState("");
    const handleInfoClick = (id) => {
        setAssetId(id);
        setDialogState(true)
    }

    return (
        <>
            {assets.map((nft, index) => {
                //console.log(nft[1].metadata)
                return (
                    <Grow
                        in={true}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(true ? { timeout: 3000 } : {})}
                        key={index}
                    >
                        <Card key={index} sx={{ minWidth: 215, maxWidth: 225, margin: 2 }}>
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    {nft[1].assetName}
                                </Typography>
                            </CardContent>
                            <CardMedia
                                component="img"
                                alt="Ticket Logo"
                                height="115"
                                image={nft[1].metadata ? nft[1].metadata.image : 'https://rudemining.com/klv/logo.png'}
                            />
                            <CardContent>
                                <Typography variant="body1" color="text.primary" component="div">
                                    Date: {nft[1].metadata ? nft[1].metadata.date ? nft[1].metadata.date : "........" : "........"}
                                </Typography>
                                <Typography variant="body3" color="text.primary"  component="div">
                                    Where: {nft[1].metadata ? nft[1].metadata.location ? nft[1].metadata.location : "........" : "........"}
                                </Typography>
                                <Typography variant="body3" color="text.primary" component="div">
                                    Section: {nft[1].metadata ? nft[1].metadata.attributes ? filterAttribute(nft[1].metadata.attributes, 'sectionName').value : "........" : "........"}
                                </Typography>
                                <Typography variant="body3" color="text.primary"  component="div">
                                    Row: {nft[1].metadata ? nft[1].metadata.attributes ? filterAttribute(nft[1].metadata.attributes, 'rowName').value : "........" : "........"}
                                </Typography>
                                <Typography variant="body3" color="text.primary" component="div">
                                    Seat: {nft[1].metadata ? nft[1].metadata.attributes ? filterAttribute(nft[1].metadata.attributes, 'seatNum').value : "........" : "........"}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" component="div">
                                    {nft[1].assetId}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small"><Link className="cardButton" to={"/event/"+splitName(nft[1].assetId)}>{"To Event"}</Link></Button>
                                <Button size="small"><Link className="cardButton" to={"/market/sell/"+nft[1].assetId}>{"Sell Ticket"}</Link></Button>
                            </CardActions>
                        </Card>
                    </Grow >
                )
            })}

            <CardBalanceDialog open={dialogState} setOpen={setDialogState} assetId={assetId} />
        </>
    );
}
const WalletCards = ({ data }) => {
    const assets = Object.entries(data)
    const [dialogState, setDialogState] = useState(false);
    const [assetId, setAssetId] = useState("");
    const handleInfoClick = (id) => {
        setAssetId(id);
        setDialogState(true)
    }
    return (
        <>
            {assets.map((nft, index) => {
                return (
                    <Grow
                        in={true}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(true ? { timeout: 3000 } : {})}
                        key={index}
                    >
                        <Card key={index} sx={{ maxWidth: 225, margin: 2 }}>
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    {nft[1].assetName}
                                </Typography>
                                <Typography variant="h7" color="text.secondary">
                                    By: {shortenWalletAddr(nft[1].address, 5)}
                                </Typography>
                            </CardContent>

                            <CardMedia
                                component="img"
                                alt="Ticket Logo"
                                height="115"
                                image={nft[1].logo ? nft[1].logo : 'https://rudemining.com/klv/logo.png'}
                            />
                            <CardContent>
                                <Typography variant="body1" color="text.primary">
                                    Date: {nft[1].metadata ? nft[1].metadata.date ? nft[1].metadata.date : "........" : "........"}
                                </Typography>
                                <Typography variant="body3" color="text.secondary">
                                    {nft[1].assetId}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Balance: {nft[1].balance}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small"><Link className="cardButton" to={nft[1].assetId ? "/event/"+splitName(nft[1].assetId) : ""}>{"To Event"}</Link></Button>
                                <Button size="small" onClick={(e) => handleInfoClick(nft[1].assetId)}>View</Button>
                            </CardActions>
                        </Card>
                    </Grow >
                )
            })}

            <CardBalanceDialog open={dialogState} setOpen={setDialogState} assetId={assetId} />
        </>
    );
}
const MarketCards = ({ data }) => {
    return (
        data.map((nft, index) => {
            
            return (
                <Grow
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(true ? { timeout: 3000 } : {})}
                    key={index}
                >
                    <Card sx={{ width: 225, margin: 2 }}>
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                                {nft.eventName}
                            </Typography>
                        </CardContent>

                        <CardMedia
                            component="img"
                            alt="Ticket Logo"
                            height="115"
                            image={nft ? nft.logo ? nft.logo : 'https://rudemining.com/kl/logo.png' : 'https://rudemining.com/kl/logo.png'}
                        />
                        <CardContent>
                            <Typography variant="body1" color="text.primary">
                                Event Date: {nft.eventDate.length ? nft.eventDate : "TBD"}
                            </Typography>
                            <Typography variant="body2" color="text.primary">
                                Location: {nft.eventLocation.length ? nft.eventLocation : "TBD"}
                            </Typography>
                            <Typography variant="body2" color="text.primary">
                                Tickets for sale:  {nft ? nft.assets ? nft.assets.length : "..." : "..."}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Asset ID:  {nft ? nft.assetId : "......"}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small"><Link className="cardButton" to={nft ? "/event/"+nft.assetId : "..."}>{"More Info"}</Link></Button>
                        </CardActions>
                    </Card>
                </Grow>
            )
        })
    );
}

export { WalletCards, MarketCards, DetailedCards, DetailedCardsBuy }