
// Denepdencies
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import { Item, ItemSecondary } from "../theme";
import { useSdk } from '../hooks/KleverHook.tsx'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// Components
import ConfirmationDialog from '../components/confirmTransDialog';
import SellOrder from "../components/sellOrder";

// Utils
import { convertToURI } from "../utils";

// Hooks


const UpdateAsset = () => {
    const sdk = useSdk();
    const isLoaded = sdk.isLoaded()
    const account = sdk.getAccount();
    const [toURI, setToURI] = useState(false);
    const [logo, setLogo] = useState("");
    const [uris, setUris] = useState("");
    const [meta, setMeta] = useState(``);
    const [receiver, setReciever] = useState("");
    const [payload, setPayload] = useState({
        triggerType: -1,
        assetID: "",
    });
    const [message, setMessage] = useState("");
    const [rawTX, setRawTX] = useState(undefined)

    const handleButtonClickUpdate = async () => {
        if (isLoaded) {
            setMessage("")
            let previousTX = undefined;
            let props = {
                autobroadcast: false,
                previousTX: previousTX,
            }


            // handle success
            //console.log(response);
            if (logo.length) {
                if (toURI) {
                    setLogo(convertToURI(logo, toURI))
                }
                previousTX = await account.sendAssetTrigger({
                    triggerType: 10,
                    assetID: payload.assetID,
                    logo: logo,
                }, { ...props, previousTX: previousTX })
                    .catch(function (error) {
                        // handle error
                        console.log(`Error: ${error.message}`);
                        setMessage(error.message)
                    })
            }
            if (uris.length) {
                previousTX = await account.sendAssetTrigger({
                    triggerType: 11,
                    assetID: payload.assetID,
                    uris: uris,
                }, { ...props, previousTX: previousTX })
                    .catch(function (error) {
                        // handle error
                        console.log(`Error: ${error.message}`);
                        setMessage(error.message)
                    })
            }
            if (meta.length) {
                previousTX = await account.sendAssetTrigger({
                    triggerType: 8,
                    assetID: payload.assetID,
                    receiver:  receiver,
                }, { ...props, metadata: JSON.stringify(meta), previousTX: previousTX })
                    .catch(function (error) {
                        // handle error
                        console.log(`Error: ${error.message}`);
                        setMessage(error.message)
                    })
            }
            setRawTX(previousTX);
        }
    }

    return (
        <>
            <Box>
                <Item>
                    <Box className="mintAsset"
                        sx={{
                            '& > :not(style)': { m: 1.5 },
                        }}
                    >
                        <h2>Update Asset</h2>
                        {/* <TextField
                            disabled
                            variant="standard"
                            id="triggerType"
                            label="triggerType:"
                            value={payloadMint.triggerType}
                            onChange={(e) => setPayloadMint({ ...payloadMint, triggerType: e.target.value })}
                        /> */}
                        <TextField
                            required
                            variant="standard"
                            id="assetID"
                            label="assetID:"
                            value={payload.assetID}
                            onChange={(e) => setPayload({ ...payload, assetID: e.target.value })}
                        />
                        <TextField
                            required
                            variant="standard"
                            id="uris"
                            label="URIs:"
                            value={uris}
                            onChange={(e) => setUris(e.target.value)}
                        />
                        <TextField
                            sx={{
                                '& > :not(style)': { width: '40ch' },
                            }}
                            required
                            variant="standard"
                            id="logo"
                            label="Logo:"
                            value={logo}
                            onChange={(e) => setLogo(e.target.value)}
                        />
                        <FormControlLabel labelPlacement="start" control={
                            <Checkbox
                                checked={toURI}
                                onChange={(e) => setToURI(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="URL to URI?" />
                        <TextField
                            sx={{
                                '& > :not(style)': { width: '40ch' },
                            }}
                            required
                            variant="standard"
                            id="meta"
                            label="Metadata:"
                            value={meta}
                            onChange={(e) => setMeta(e.target.value)}
                        />
                        <TextField
                            sx={{
                                '& > :not(style)': { width: '40ch' },
                            }}
                            required
                            variant="standard"
                            id="receiver"
                            label="Receiver Wallet Address (KLV):"
                            value={receiver}
                            onChange={(e) => setReciever(e.target.value )}
                        />
                    </Box>
                    <Button variant="contained" name="btnCreateMarket" onClick={handleButtonClickUpdate}>
                        Update Asset
                    </Button>
                    <span>{message}</span>
                </Item>
            </Box>
            <ConfirmationDialog data={rawTX} />
        </>
    );
};

export default UpdateAsset;