import { useState, useEffect } from "react";

const useWalletState = () => {
  const [data, setData] = useState(false);

  useEffect(() => {
    if(sessionStorage.getItem('walletAddress')){
      setData(true);
    }else{
      setData(false);
    }
  }, []);

  return data;
};

export default useWalletState;