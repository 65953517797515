// Dependecies
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';


// Componets
import SellOrder from "../components/sellOrder";
import { type } from "@testing-library/user-event/dist/type";

// Utils

// Hooks


const SellNFTs = () => {
    const { assetId, nftId } = useParams();

    const asset = () => {
        if( !assetId || !nftId ){
            console.log(`no no`)
            console.log(assetId)
            console.log(nftId)
            return "";
        }else {
            const asset = assetId+"/"+nftId
            console.log(asset)
            return asset;
        }
        
        
    }
    return (
        <>
            <Box
                sx={{
                    '& > :not(style)': { m: 1.5 },
                }}
            >
                <h1>Sell Ticket</h1>
                <Box >
                    <SellOrder nft={asset()}/>
                </Box>
            </Box>
        </>
    );
};

export default SellNFTs;
