// Dependecies
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// Componets
import { DetailedCardsBuy } from '../components/nftCards';

// Utils
import { getAssetLogo } from "../utils/";

// Hooks
import useWalletState from "../hooks/useWalletState";

const Event = () => {
    let { assetId } = useParams();
    const [loading, setLoading] = useState(true);
    const axios = require('axios').default;
    const [filter, setFilter] = useState('all');
    const [event, setEvent] = useState({
        name: <Skeleton count={1}/>,
        logo: <Skeleton count={5}/>,
        eventDate: <Skeleton count={1}/>,
        description: <Skeleton count={1}/>,
        location: <Skeleton count={1}/>,

    });
    const [nfts, setNfts] = useState({});

    const handleClickBuyButton = (event) => {
        setFilter(event.target.value);
    };

    useEffect(() => {
        axios.get(`https://api.testnet.klever.finance/v1.0/marketplaces/bf7a48464aaaf289`)
            .then(function (res) {
                // handle success
                let nfts = [];
                const data = Object.entries(res.data.data.marketplace.assets).map((nft) => {
                    const assetNameArry = nft[0].split("/");
                    const assetName = assetNameArry[0];

                    if (assetId === assetName) {
                        //console.log(nft[0]);
                        nfts.push(nft[1]);
                    }
                })
                //console.log(nfts)
                setEvent({
                    name: nfts[0].metedata ? nfts[0].metedata.name : "TBD",
                    eventDate: nfts[0].metedata ? nfts[0].metedata.eventDate : "TBD",
                    description: nfts[0].metedata ? nfts[0].metedata.description : "TBD",
                    location: nfts[0].metedata ? nfts[0].metedata.location : "TBD",
                })
                setNfts(nfts)
            })
            .catch(function (error) {
                // handle error
                console.log(`Error: ${error}`);
            })
            .then(function () {
                // always executed
                setLoading(false)
            });
    }, [assetId])
    return (
        <>
            <Box
                sx={{
                    '& > :not(style)': { m: 1.5 },
                }}
            >
                <h1>Event Info</h1>
                <Box >
                    <Paper className="event" variant="outlined" sx={{ padding: 1 }}>
                        <>
                            <Typography variant="h6" component="div" gutterBottom>
                                Event Name: {event.name ? event.name : 'TBD'}
                            </Typography>
                            <Typography sx={{ fontSize: 18 }} color="text.primary" gutterBottom>
                                Date:  {event.eventDate ? event.eventDate : "TBD"}
                            </Typography>
                                <img src={event.logo ? event.logo : 'https://rudemining.com/klv/logo.png'}
                                    alt="Event Main Picture"
                                    width="300"
                                    height="250"
                                />
                            <Typography variant="h6" component="div" gutterBottom>
                                Location:  {event.location ? event.location : "TBD"}
                            </Typography>
                            <Typography variant="h6" component="div" gutterBottom>
                                Description:  {event.description ? event.description : "TBD"}
                            </Typography>
                        </>
                    </Paper>
                </Box>
            </Box>
            <Box
                sx={{
                    '& > :not(style)': { m: 1.5 },
                }}
            >
                <Paper className="eventNFTs" variant="outlined" sx={{ padding: 1 }}>
                    <div className='cardlist'>
                        <DetailedCardsBuy data={nfts} sx={{ display: 'block' }} />
                    </div>
                </Paper>
            </Box>
        </>
    );
};

export default Event;
