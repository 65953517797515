// Depenecies
import { useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Item, ItemSecondary } from "../theme";
import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Autocomplete from "react-google-autocomplete";
import { create } from "ipfs-http-client";

// Components
import CreateAsset from "../components/createAsset";
import ObjectStringDialog from "../components/objectStringDialog"

// Utils
import { convertToURI,  objectBuilder } from "../utils";

const generalSection = () =>  {
    const OpenSeats = function (name){
        this.type = 'Unassigned';
        this.name = name;
        this.seats = 0;
    }
    OpenSeats.prototype.updateName = function (name){
        if (name){
            this.name = name
            return true;
        }
        return false
    }
    OpenSeats.prototype.updateSeats = function (seats){
        if (seats){
            this.seats = seats
            return true;
        }
        return false
    }
    return new OpenSeats('New General')
}


const assignedSection = () =>  {
    const Section = function (name){
        this.type = 'Assigned';
        this.name = name;
        this.rows = [];
    }
    Section.prototype.updateName = function (name){
        if (name){
            this.name = name
            return true;
        }
        return false
    }
    Section.prototype.addRow = function (){
        this.rows.push(rows())
        return true
    }
    Section.prototype.removeRow = function (index){
        let tempRows = [...this.rows]
        const temp = tempRows.splice(index,1)
        this.rows = [...tempRows]
        return this.rows
    }
    let sec = new Section("New Section");
    sec.addRow()
    return sec
}

const rows = () =>  {
    const Row = function (name){
        this.name = name;
        this.seats = 0;
    }
    Row.prototype.updateName = function (name){
        if (name){
            this.name = name
            return true;
        }
        return false
    }
    Row.prototype.updateSeats = function (seats){
        if (seats){
            this.seats = seats
            return true;
        }
        return false
    }
    let row = new Row("New Row");
    return row
}

const role = () =>  {
    const Role = function (){
        this.address = "";
        this.hasRoleMint = false;
        this.hasRoleSetITOPrices = false;
    }
    Role.prototype.removeItem = function (index){
        
        return this.roles
    }
    return new Role()
}

const TicketCreator = ()  => {
    const [metadata, setMetadata] = useState({});
    const [section, setSection] = useState([]);
    const [general, setGeneral] = useState([]);
    const [num, setNum] = useState(0);
    const [row, setRow] = useState([]);
    const [toURI, setToURI] = useState(false);
    const [assetInfo, setAssetInfo] = useState({
        name: "",                   // Asset name
        ticker: "",                 // Asset shorthand
        owner: sessionStorage.getItem('walletAddress') || "",                  // Owner address
        precision: 0,               // Asset precision (0 to 8)
        logo: "",                   // Logo uri
        uris: new Map(),                   // 
        maxSupply: 0,               // Defined by total seats
        type: 1,                    // 0: Fungible Token, 1: NFT
        eventTime: new Date('2022-09-21T22:09:21'),
        eventLocation: "",
        description:  ""
    });
    const [royalties, setRoyalties] = useState({
        address: "",            // Royalty receiver address
        transferFixed: 0,       // Transfer fee details for non-fungible tokens
        marketPercentage: 0,    // Market percentage fee details for non-fungible tokens (precision 2)
        marketFixed: 0,         // Market fixed fee details for non-fungible tokens
    });
    const [royaltyInfo, setRoyaltyInfo] = useState({
        amount: 0,              // Max amount for that percentage
        percentage: 0,          // Fee % (precision 2)
    });
    const [roles, setRoles] = useState([]);
    const [properties, setProperties] = useState({
        canFreeze: true,         // Should be freezable?
        canWipe: false,          // Should be wipeable?
        canPause: true,          // Should be pausable?
        canMint: true,           // Should be mintable?
        canBurn: false,          // Should be burnable?
        canChangeOwner: false,   // Should the owner be changeable?
        canAddRoles: true,      // Should roles be add-able?
    });
    const [attributes, setAttributes] = useState({
        isPaused: false,             // Should be currently paused?
        isNFTMintStopped: false,     // Should be currently NFT mint stopped?
    });
    const [urlArr, setUrlArr] = useState([]);
    const addGeneral = () => {
        let tmpGen = [...general]
        tmpGen.push(generalSection())
        setGeneral(tmpGen)
        console.log(tmpGen)
    }
    const addSection = () => {
        let temp = [...section]
        temp.push(assignedSection())
        console.log(temp)
        setSection(temp)
    }
    const removeSection = (e, index, sec) => {
        let secs = [...sec]
        const temp = secs.splice(index,1)
        if(e.target.name === 'general'){
            setGeneral(secs)
        }else if(e.target.name === 'section'){
            setSection(secs)
        }
    }
    const addRow = (sec) => {
        //Hack to render
        setRow(sec.rows.push(rows()))
    }
    const removeRow = (sec, index) => {
        //Hack to render
        setRow(sec.removeRow(index))
    }
    const updateSeats = (row) => {
        //Hack to render
        setNum( row.seats += 1)
    }
    const addRole = () => {
        let temproles = [...roles]
        temproles.push(role())
        setRoles(temproles)
        console.log(temproles)
    }
    // IPFS handlers
    const client = create('https://ipfs.infura.io:5001/api/v0');
    const addDataToIPFS = async (data) => {
        try {
            //console.log(data)
            const created = await client.add(data);
            const url = `https://ipfs.infura.io/ipfs/${created.path}`;
            setUrlArr(prev => [...prev, url]);
            console.log(urlArr); 
            setAssetInfo({...assetInfo, uris: urlArr[0]})

        } catch (error) {
            console.log(error.message);
        }
    }
    // Need to update as dups are linked on change
    const duplicateSection = (sec, index) => {
        let secs = [...section]
        secs.splice(index, 0, sec)
        setSection(secs)
    }
    function handleChangeInput(event, secIndex, rowIndex, sec) {
        let temp  = [...sec];
        let name = event.target.name || event.target.id;
        if(name === "sectionName"){
            temp[secIndex].name = event.target.value
            setSection(temp)
        }
        else if(name === "rowName"){
            temp[secIndex].rows[rowIndex].name = event.target.value
            setSection(temp)
        }    
        else if(name === "seatCount"){
            temp[secIndex].rows[rowIndex].seats = event.target.value
            setSection(temp)
        }else if(name === "generalSectionName"){
            temp[secIndex].name = event.target.value
            setGeneral(temp)
        }else if(name === "generalSeatCount"){
            temp[secIndex].seats = event.target.value
            setGeneral(temp)
        }
    }
    function handleChangeTextField(event, index, array, setFunc) {
        let temp = [...array];
        const id = event.target.id;
        const val = event.target.value;
        temp[index][id] = val;
        setFunc(temp);
    }
    function removeObject(index, array, setFunc) {
        // Hack to render
        let temp = [...array]
        let newArry = temp.splice(index,1)
        setFunc(temp)
    }
    function handleRolesCheckbox (e, index, key) {
        let temp  = [...roles];
        temp[index][key] = e.target.checked
        setRoles(temp)
    }
    const totalSeatCount = () => {
        let total = 0
        section.forEach( sec => {
            sec.rows.forEach( row  => {
                total += parseInt(row.seats)
            })
        });
        general.forEach( sec => {
            total += parseInt(sec.seats)
        });
        return total
    }
    const ticketListGeneral = () => {
        let list = []
        general.forEach( sec => {
            
                for (let i = 0; i < sec.seats; i++){
                    list.push(`${assetInfo.name} -> Section: ${sec.name} Seat: ${i + 1}` )
                }
        
        });
        return list
    }
    const ticketListSection = () => {
        let list = []
            section.forEach( sec => {
                Object.keys(sec.rows).map(function(key, index) {
                    for (let i = 0; i < sec.rows[key].seats; i++){
                        list.push(`${assetInfo.name} -> Section: ${sec.name} Row: ${sec.rows[key].name} Seat: ${i + 1}` )
                    }
                });
            });
        return list
    }
    function payload () {
        const royaltyData = {
            ...royalties,
            royaltyInfo
        }
        const pl = {
            ...assetInfo,
            logo: convertToURI(assetInfo.logo, toURI),
            //uris: convertToURI(assetInfo.uris, toURI),
            precision: parseInt(assetInfo.precision),
            maxSupply: totalSeatCount(),
            royalties: {...royaltyData},
            roles: [...roles],
            properties: {...properties},
            attributes: {...attributes}
        };
        return pl
    }
    return(
            <>
                <Box
                    sx={{
                        '& > :not(style)': { p: 1.5 },
                    }}
                >
                    <h1>Ticket Creator</h1>
                    <CreateAsset 
                        payload={payload()}
                        general={general} 
                        section={section}
                    />
                </Box>
                <Box>
                    <Item>
                        <Box className="generalInfo"
                            // sx={{
                            //     width: 700,
                            //     maxWidth: '100%',
                            //     display: 'flex',
                            //     flexWrap: 'wrap',
                            //   }}
                            sx={{
                                '& > :not(style)': { m: 1.5 },
                            }}
                        >
                            <h2>General Info</h2>
                            {/* <p>Total seats / Max Supply: {totalSeatCount()}</p> */}
                            <p>Expected data structure for metadata: {"{image: string, description: string, date: string, name: string," +
                                "attributes: [{trait_type: sectionName, value: string},{trait_type: rowName, value: string},{trait_type: seat, value: string}]}"} </p>
                            <TextField
                                required
                                variant="standard"
                                id="eventName"
                                label="Event Name:"
                                value={assetInfo.name} 
                                onChange={(e) => setAssetInfo({...assetInfo, name: e.target.value})}
                            />
                            <TextField
                                required
                                variant="standard"
                                id="eventNameTicker"
                                label="Ticker: "
                                value={assetInfo.ticker} 
                                onChange={(e) => setAssetInfo({...assetInfo, ticker: e.target.value})}
                            />
                            <TextField
                                required
                                variant="standard"
                                id="autocomplete"
                                label="Location: "
                                value={assetInfo.eventLocation} 
                                onChange={(e) => setAssetInfo({...assetInfo, eventLocation: e.target.value})}
                            />
                            {/* <Autocomplete
                                apiKey={"AIzaSyCOraVYgncC7yiIC1CoGBOvhzi7DgipQHU"}
                                onPlaceSelected={(place) => console.log(place)}
                            /> */}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                    <MobileDatePicker
                                    label="Event Date"
                                    inputFormat="MM/dd/yyyy"
                                    value={assetInfo.eventTime}
                                    onChange={(e) => setAssetInfo({...assetInfo, eventTime: e})}
                                    renderInput={(params) => <TextField {...params} />}
                                    />
                                    <TimePicker
                                    label="Event Time"
                                    value={assetInfo.eventTime}
                                    onChange={(e) => setAssetInfo({...assetInfo, eventTime: e})}
                                    renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <TextField
                                sx={{
                                    '& > :not(style)': { width: '40ch' },
                                }}
                                id="description"
                                label="Event Description"
                                multiline
                                rows={4}
                                value={assetInfo.description} 
                                onChange={(e) => setAssetInfo({...assetInfo, description: e.target.value})}
                            />
                            <TextField
                                sx={{
                                    '& > :not(style)': { width: '40ch' },
                                }}
                                required
                                variant="standard"
                                id="ownerWallet"
                                label="Owner Wallet Address (KLV):"
                                value={assetInfo.owner} 
                                onChange={(e) => setAssetInfo({...assetInfo, owner: e.target.value})}
                            />
                            <TextField
                                required
                                disabled
                                type="number"
                                variant="standard"
                                id="precision"
                                label="Asset Precision:"
                                value={assetInfo.precision}
                                onChange={(e) => setAssetInfo({...assetInfo, precision: e.target.value})}
                            />
                            <TextField
                                required
                                type="number"
                                variant="standard"
                                id="maxSupply"
                                label="Max Supply:"
                                value={assetInfo.maxSupply}
                                onChange={(e) => setAssetInfo({...assetInfo, maxSupply: e.target.value})}
                            />
                            <div>
                                <TextField
                                    sx={{
                                        '& > :not(style)': { width: '33ch' },
                                    }}
                                    variant="standard"
                                    id="uriLogo"
                                    label="Logo URI: "
                                    value={assetInfo.logo} 
                                    onChange={(e) => setAssetInfo({...assetInfo, logo: e.target.value})}
                                />
                                 <FormControlLabel labelPlacement="start" control={
                                    <Checkbox
                                    checked={toURI}
                                    onChange={ (e) => setToURI(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } label="URL to URI?" />
                            </div>
                            
                            <TextField
                                sx={{
                                    '& > :not(style)': { width: '33ch' },
                                }}
                                variant="standard"
                                id="uris"
                                label="URIs: "
                                value={assetInfo.uris} 
                                onChange={(e) => setAssetInfo({...assetInfo, uris: e.target.value})}
                            />

                            <Button variant="contained" onClick={ () => setAssetInfo({...assetInfo, uris: new Map(Object.entries(objectBuilder([...general,...section], assetInfo)))})}>
                                Ticket Creator to URIs
                            </Button>
                            {/* <TextField
                                disabled
                                variant="standard"
                                id="maxSupply"
                                label=" Max Supply:"
                                value={totalSeatCount()}
                            /> */}
                        </Box>
                    </Item>
                    <Item>
                        <Box className="royaltyInfo"
                            sx={{
                                '& > :not(style)': { m: 1.5 },
                            }}
                        >
                            <h2>Royalties</h2>
                            <TextField
                                required
                                variant="standard"
                                sx={{
                                    '& > :not(style)': { width: '40ch' },
                                }}
                                id="royaltyWallet"
                                label="Wallet Address (KLV):"
                                value={royalties.address} 
                                onChange={(e) => setRoyalties({...royalties, address: e.target.value})}
                            />
                            <TextField
                                required
                                type="number"
                                variant="standard"
                                id="royaltyTransferFixed"
                                label="Transfer Fixed:"
                                value={royalties.transferFixed} 
                                onChange={(e) => setRoyalties({...royalties, transferFixed: parseInt(e.target.value)})}
                            />
                            <TextField
                                required
                                type="number"
                                variant="standard"
                                id="royaltyMarketPercentage"
                                label="Market Percentage:"
                                value={royalties.marketPercentage} 
                                onChange={(e) => setRoyalties({...royalties, marketPercentage: parseInt(e.target.value)})}
                            />
                            <TextField
                                required
                                type="number"
                                variant="standard"
                                id="royaltyMarketFixed"
                                label="Market Fixed:"
                                value={royalties.marketFixed} 
                                onChange={(e) => setRoyalties({...royalties, marketFixed: parseInt(e.target.value)})}
                            />
                            <TextField
                                required
                                type="number"
                                variant="standard"
                                id="royaltyTransferAmount"
                                label="Transfer Amount:"
                                value={royaltyInfo.amount} 
                                onChange={(e) => setRoyaltyInfo({...royaltyInfo, amount: parseInt(e.target.value)})}
                            />
                            <TextField
                                required
                                type="number"
                                variant="standard"
                                id="royaltyTransferPercentage"
                                label="Transfer Percentage:"
                                value={royaltyInfo.percentage} 
                                onChange={(e) => setRoyaltyInfo({...royaltyInfo, percentage: parseInt(e.target.value)})}
                            />
                        </Box>
                    </Item>
                    <Item>
                        <Box className="roles"
                            sx={{
                                '& > :not(style)': { m: 1.5 },
                            }}
                        >
                            <h2>Roles</h2>
                            <Button variant="contained" onClick={ () => addRole()}>
                                Add Role
                            </Button>
                            {roles.map( (role, roleIndex) => {
                                return(
                                    <Item key={roleIndex}>
                                        <Box className="role" 
                                            sx={{
                                                '& > :not(style)': { m: 1.5 },
                                            }}
                                        >
                                            <TextField
                                                required
                                                id="address"
                                                label="Wallet address:"
                                                value={roles[roleIndex].address} 
                                                onChange={(e) => handleChangeTextField(e, roleIndex, roles, setRoles)}
                                            />
                                            <FormControlLabel labelPlacement="top" control={
                                                <Checkbox
                                                    checked={roles[roleIndex].hasRoleMint}
                                                    onChange={ (e) => handleRolesCheckbox(e, roleIndex, "hasRoleMint")}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            } label="Can Mint" />
                                            <FormControlLabel labelPlacement="top" control={
                                                <Checkbox
                                                    checked={roles[roleIndex].hasRoleSetITOPrices}
                                                    onChange={ (e) => handleRolesCheckbox(e, roleIndex, "hasRoleSetITOPrices")}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            } label="Can Set ITO Prices:" />
                                            <Button variant="contained" name="general" onClick={(e) => removeObject(roleIndex, roles, setRoles)}>
                                                Remove Role
                                            </Button>
                                        </Box>
                                    </Item>
                                );
                            })}
                        </Box>
                    </Item>
                    <Item>
                        <Box className="properties"
                            sx={{
                                '& > :not(style)': { m: 1.5 },
                            }}
                        >
                            <h2>Properties</h2>
                            {Object.keys(properties).map((key,index) => {
                                return(
                                    <FormControlLabel key={index} control={
                                        <Checkbox
                                            checked={properties[key]}
                                            onChange={ (e) => setProperties({...properties, [key]: e.target.checked})}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    } label={key} />
                                )
                            })}
                        </Box>
                    </Item>
                    <Item>
                        <Box className="attributes"
                            sx={{
                                '& > :not(style)': { m: 1.5 },
                            }}
                        >
                            <h2>Attributes</h2>
                            {Object.keys(attributes).map((key,index) => {
                                return(
                                    <FormControlLabel key={index} control={
                                        <Checkbox
                                            checked={attributes[key]}
                                            onChange={ (e) => setAttributes({...attributes, [key]: e.target.checked})}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    } label={key} />
                                )
                            })}
                        </Box>
                    </Item>
                </Box>
                

                <Box className="ticketCreator"
                    sx={{
                        '& > :not(style)': { m: 1.5 },
                    }}
                >
                    <h2> Ticket JSON Creater</h2>
                    <Button  variant="contained" onClick={ () => addGeneral()}>
                        Add General
                    </Button>
                    <Button  variant="contained" onClick={ () => addSection()}>
                        Add Section
                    </Button>
                    <ObjectStringDialog data={[...general,...section]} ticketInfo={assetInfo}/>
                </Box>
               
                    {general.map( (gen, genIndex) => {
                        return(
                            <Item key={genIndex}>
                                <Box className="section" 
                                    sx={{
                                        '& > :not(style)': { m: 1.5 },
                                    }}
                                >
                                    <TextField
                                        required
                                        id="generalSectionName"
                                        label="General Section Name:"
                                        value={general[genIndex].name} 
                                        onChange={(e) => handleChangeInput(e, genIndex, undefined, general)}
                                    />
                                    <TextField
                                        required
                                        id="generalSeatCount"
                                        label="Seats:"
                                        value={general[genIndex].seats} 
                                        onChange={(e) => handleChangeInput(e, genIndex, undefined, general)}
                                    />
                                    <Button variant="contained" name="general" onClick={(e) => removeSection(e, genIndex, general)}>
                                        Remove section
                                    </Button>
                                    <Button disabled variant="contained"  onClick={() => duplicateSection(gen, genIndex)}>
                                        Duplicate Section
                                    </Button>
                                </Box>
                            </Item>
                        );
                    })}
                
                    {section.map( (sec, secIndex) => {
                        return(
                            <Item key={secIndex}>
                                <Box className="section" 
                                    
                                    sx={{
                                        '& > :not(style)': { m: 1.5 },
                                    }}
                                >
                                    <TextField
                                        required
                                        id="sectionName"
                                        label="Section Name:"
                                        value={section[secIndex].name} 
                                        onChange={(e) => handleChangeInput(e, secIndex, undefined, section)}
                                    />
                                    <Button variant="contained" onClick={() => addRow(sec)}>
                                        Add Row
                                    </Button>
                                    <Button variant="contained" disabled onClick={() => duplicateSection(sec, secIndex)}>
                                        Duplicate Section
                                    </Button>
                                    <Button variant="contained" name="section" onClick={(e) => removeSection(e, secIndex, section)}>
                                        Remove section
                                    </Button>
                                    <>
                                        <ItemSecondary>
                                        {sec.rows.map( (row, rowIndex) => {
                                            return(
                                                <Box 
                                                    className="row" 
                                                    key={rowIndex} 
                                                    sx={{
                                                        marginLeft:"50px",
                                                        '& > :not(style)': { m: 1.5 },
                                                    }}
                                                >
                                                    <TextField
                                                        required
                                                        id="rowName"
                                                        label="Row Name:"
                                                        value={row.name} 
                                                        onChange={(e) => handleChangeInput(e, secIndex, rowIndex, section)}
                                                    />
                                                    <TextField
                                                        required
                                                        id="seatCount"
                                                        label="Seats:"
                                                        value={row.seats} 
                                                        onChange={(e) => handleChangeInput(e, secIndex, rowIndex, section)}
                                                    />
                                                    <Button variant="contained" onClick={() => removeRow(sec, rowIndex)}>
                                                        Remove Row
                                                    </Button>
                                                </Box>
                                            );
                                        })}
                                        </ItemSecondary>
                                    </>
                                </Box>
                            </Item>
                        );
                    })}
                <Box>
                    {ticketListSection().map( (ticket,index) => <p key={index}> {ticket}</p>)}
                    {ticketListGeneral().map( (ticket,index) => <p key={index}> {ticket}</p>)}
                </Box>
            </>
        
    );
}

export default TicketCreator