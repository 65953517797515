// Dependencies
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { core } from '@klever/sdk';

// Hooks


// Utils
import { convertPrecision } from '../utils';

function ConfirmationDialogRaw(props) {
    const { data, onClose, sendTransaction, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    React.useEffect(() => {
        if (!open || data.BandwidthFee) {
            setValue(valueProp);
        }
    }, [valueProp, open, data]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        console.log(`user clicked OK`)
        sendTransaction();
        onClose(value);
        
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >

            <DialogTitle>Confirm Transaction</DialogTitle>
            <DialogContent dividers>
                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                    Bandwidth Fee:
                </Typography>
                <Typography variant="h6" component="div">
                    {data ? convertPrecision(data[0].RawData.BandwidthFee, 6, "real") : ""}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    KAppFee:
                </Typography>
                <Typography variant="h6" component="div">
                    {data ? convertPrecision(data[0].RawData.KAppFee, 6, "real") : ""}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Total:
                </Typography>
                <Typography variant="h5" component="div">
                    {data ? convertPrecision(data[0].RawData.KAppFee, 6, "real") + convertPrecision(data[0].RawData.BandwidthFee, 6, "real")+"(KLV)": ""}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    Cancel
                </Button>
                <Button onClick={handleOk}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

export default function ConfirmationDialog({ data }) {
    const axios = require('axios').default;
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('Dione');
    const [txStatus, setStatus] = React.useState('');

    const sendTransaction = async () => {
        await core.broadcastTransactions(JSON.stringify(data))
        .then( async function (response) {
            // handle success
            const res = await axios.get(`https://api.testnet.klever.finance/transaction/${response.txHashes[0]}`);
            setStatus(res.data)
            // TODO: Add in snackBar for UI notification
            console.log(res.data)
            
        })
        .catch(function (error) {
            // handle error
            console.log(`Error: ${error}`);
        })
        .then(function () {
            // always executed
        });
    };

    const handleClose = (newValue) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    React.useEffect(() => {
        if (data)
            setOpen(true);

    }, [data]);

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ConfirmationDialogRaw
                id="ringtone-menu"
                keepMounted
                open={open}
                data={data}
                onClose={handleClose}
                sendTransaction={sendTransaction}
                value={value}
            />
        </Box>
    );
}
