import { Outlet, Link } from "react-router-dom";
import NavBar from "../components/navBar";
import Box from '@mui/material/Box';


const Layout = () => {
  return (
    <>
        <NavBar />
        <Box className="pageContent">
          <Outlet />  
        </Box>
    </>
  )
};

export default Layout;