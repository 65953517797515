
// Denepdencies
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import { Item, ItemSecondary } from "../theme";
import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// Components
import ConfirmationDialog from '../components/confirmTransDialog';

// Utils
import convertAssetBase from "../utils/convertAssetBase";

// Hooks
import { useSdk } from '../hooks/KleverHook.tsx'

const SellOrder = ({nft}) => {
    const sdk = useSdk();
    const isLoaded = sdk.isLoaded()
    const account = sdk.getAccount();
    const [sell, setSell] = useState({
        price: 0,
        reservePrice: 0,
    });
    const [payloadSellOrder, setPayloadSellOrder] = useState({
        marketType: 0,
        marketplaceId: "bf7a48464aaaf289",
        assetId: nft,
        currencyId: "KLV",
        endTime: new Date('2022-09-21T22:09:21'),
    });
    const [message, setMessage] = useState("");
    const [rawTX, setRawTX] = useState(undefined)
    const handleButtonClickSellOrder = async () => {
        if(isLoaded){
            setMessage("")
            let props = {
                autobroadcast: false,
                previousTX: undefined,
            }
            let payload = {
                ...payloadSellOrder,
            }
            if(sell.price){
                payload = {
                    ...payload,
                    price: parseInt(convertAssetBase(sell.price, 6, "storage-ready"))
                }
            }
            if(sell.reservePrice){
                payload = {
                    ...payload,
                    reservePrice: parseInt(convertAssetBase(sell.reservePrice, 6, "storage-ready"))
                }
            }
            payload = {
                ...payload,
                endTime: Date.parse(payloadSellOrder.endTime) / 1000
            }
            
           await account.sendSellOrder(payload, props)
            .then(function (response) {
                // handle success
                //console.log(response);
                setRawTX(response);
            
                //setMessage("Success")
            })
            .catch(function (error) {
                // handle error
                console.log(`Error: ${error.message}`);
                setMessage(error.message)
            })
            .then(function () {
                // always executed
            });
        }
    }
    
    function endTimeToUnixTime(time)  {

        const unixTime = Date.parse(time)
        console.log(unixTime)
        return unixTime
    }

    return (
        <>
            <Box>
                <Item>
                    <Box className="sellOrder"
                        // sx={{
                        //     width: 700,
                        //     maxWidth: '100%',
                        //     display: 'flex',
                        //     flexWrap: 'wrap',
                        //   }}
                        sx={{
                            '& > :not(style)': { m: 1.5 },
                        }}
                    >
                        <h2>Create Sell Order</h2>
                        <TextField
                            required
                            disabled
                            variant="standard"
                            id="marketType"
                            label="Market Order Type:"
                            value={payloadSellOrder.marketType}
                            onChange={(e) => setPayloadSellOrder({ ...payloadSellOrder, marketType: e.target.value })}
                        />
                        <TextField
                            required
                            disabled
                            variant="standard"
                            id="marketplaceId"
                            label="Marketplace ID:"
                            value={payloadSellOrder.marketplaceId}
                            onChange={(e) => setPayloadSellOrder({ ...payloadSellOrder, marketplaceId: e.target.value })}
                        />
                        <TextField
                            required
                            variant="standard"
                            id="assetId"
                            label="Asset ID:"
                            value={payloadSellOrder.assetId}
                            onChange={(e) => setPayloadSellOrder({ ...payloadSellOrder, assetId: e.target.value })}
                        />
                        <TextField
                            required
                            disabled
                            variant="standard"
                            id="currencyId"
                            label="Currency ID:"
                            value={payloadSellOrder.currencyId}
                            onChange={(e) => setPayloadSellOrder({ ...payloadSellOrder, currencyId: e.target.value })}
                        />
                        <TextField
                            required
                            type="number"
                            variant="standard"
                            id="price"
                            label="Sale Price:"
                            value={sell.price}
                            onChange={(e) => setSell({ ...sell, price: e.target.value })}
                        />
                        <TextField
                            required
                            type="number"
                            variant="standard"
                            id="reservePrice"
                            label="Reserve Price:"
                            value={sell.reservePrice}
                            onChange={(e) => setSell({ ...sell, reservePrice: e.target.value })}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={3}>
                                <MobileDatePicker
                                label="End Date"
                                inputFormat="MM/dd/yyyy"
                                value={payloadSellOrder.endTime}
                                onChange={(e) => setPayloadSellOrder({...payloadSellOrder, endTime: e})}
                                renderInput={(params) => <TextField {...params} />}
                                />
                                <TimePicker
                                label="Time"
                                value={payloadSellOrder.endTime}
                                onChange={(e) => setPayloadSellOrder({...payloadSellOrder, endTime: e})}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Box>
                    <Button variant="contained" name="btnCreateMarket" onClick={handleButtonClickSellOrder}>
                        Send To Market
                    </Button>
                    <span>{message}</span>
                </Item>
            </Box>
            <ConfirmationDialog data={rawTX}/>
        </>
    );
};

export default SellOrder;