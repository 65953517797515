// Dependecies
import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LinearProgress from '@mui/material/LinearProgress';


import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

// Components
import { MarketCards } from "../components/nftCards";

// Utils

// Hooks


const Market = () => {
    const axios = require('axios').default;
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('all');
    const [search, setSearch] = useState("");
    const [viewActive, setViewActive] = useState(false);
    const [viewOld, setViewOld] = useState(false);
    const [nfts, setNfts] = useState([
        {
            assetId: "",
            name: "",
            assetName: "",
            logo: "https://rudemining.com/klv/logo.png",
            creator: "",
            eventLocation: "",
            eventDate: "July 1st, 2022",
            metadata: [],
        }
    ]);

    const handleRadioBtnChange = (event) => {
        setFilter(event.target.value);
    };

    const nftsFiltered = () => {
        //console.log(nfts)
        let newNfts = [...nfts]
        
        if(search.length){
            newNfts = nfts.filter((item) => item.eventName.toLowerCase().includes(search.toLowerCase()))
        }
        if (filter === "active") {
            return newNfts.filter((item) => new Date(item.eventDate) <= new Date())
        } else if (filter === "old") {
            return newNfts.filter((item) => new Date(item.eventDate) > new Date())
        } else {
            return newNfts
        }
    }

    useEffect(() => {
        // Function call to get api data
        const fetchData = async () => {
            // handle success
            const res = await axios.get(`https://api.testnet.klever.finance/marketplaces/bf7a48464aaaf289`);
            const data = res.data.data.marketplace.assets;
            //console.log(data)

            if (Object.entries(data).length) {
                const nftGroups = [];
                for (const nft of Object.entries(data)) {
                    // Check if we have correct data from blockchain and not default state
                    if (nft[1]) {
                        const assetNameArry = nft[1].assetId.split("/")
                        const assetName = assetNameArry[0]
                        if (!nftGroups.some((el) => el.assetId === assetName)) {
                            const res = await axios.get(`https://api.testnet.klever.finance/assets/${assetName}`);
                            const assetInfo = res.data.data.asset;
                            nftGroups.push({
                                assetId: assetName,
                                eventName: assetInfo.name,
                                assets: [],
                                eventDate: "",
                                eventLocation: "",
                                logo: assetInfo.logo
                            });
                        }
                        const groupIndex = nftGroups.findIndex((group) => group.assetId === assetName);
                        nftGroups[groupIndex].assets.push(nft[1]);
                    }

                }
                //console.log(nftGroups)
                setNfts(nftGroups)
            } else {
                setNfts([
                    {
                        id: "No Ticktet(s) found",
                        name: "Sorry :(",
                        logo: "https://image.shutterstock.com/image-vector/lost-items-line-vector-icon-600w-1436787446.jpg",
                        creator: "Omni",
                        eventDate: "07/01/2020",
                        metadata: [],
                    },
                ])
            }
        }

        fetchData()
            .catch(function (error) {
                // handle error
                console.log(`Error: ${error}`);
            })
            .then(function () {
                // always executed
                setLoading(false)
            });
    }, [])
    return (
        <>
            <Box
                sx={{
                    '& > :not(style)': { m: 1.5 },
                }}
            >
                <h1>Market</h1>
                <Paper
                    component="form"
                    fullwidth
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center',}}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Filter By Name Or Search"
                        inputProps={{ 'aria-label': 'Filter Or Search' }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton color="primary" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                    
                </Paper>
                <Box >
                    {loading ? <LinearProgress color="secondary" /> :
                        <Paper className="marketNFTs" variant="outlined" sx={{ padding: 1 }}>
                            <>
                                <div>
                                    
                                    <FormControl>
                                        <FormLabel id="nfts-list-display-buttons">Event Filter:</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="nfts-list-display-buttons"
                                            name="row-radio-buttons-group"
                                            value={filter}
                                            onChange={handleRadioBtnChange}
                                        >
                                            <FormControlLabel value="all" control={<Radio />} label="All" />
                                            <FormControlLabel value="active" control={<Radio />} label="Active" />
                                            <FormControlLabel value="old" control={<Radio />} label="Old" />
                                        </RadioGroup>
                                    </FormControl>

                                </div>
                                <Box sx={{ display: 'inline-flex', flexWrap: "wrap" }}>
                                    <MarketCards data={nftsFiltered()} />
                                </Box>
                            </>
                        </Paper>
                    }
                </Box>
            </Box>

        </>
    );
};

export default Market;
