const objectBuilder = (data, ticketInfo) => {
    let count = 1;
    let temp = {
      image: ticketInfo.logo || "", 
      description: ticketInfo.description || "", 
      date: ticketInfo.eventTime || "", 
      location: ticketInfo.eventLocation || "",
      name: ticketInfo.name || "",
    }
    for (let sec of data) {
      if(sec.type === "Unassigned"){ 
          for(let x = 0; x < sec.seats; x++){
              temp = {
                  ...temp,
                  [parseInt(count)]: {
                      image: ticketInfo.logo || "", 
                      description: ticketInfo.description || "", 
                      date: ticketInfo.eventTime || "", 
                      name: ticketInfo.name || "",
                      attributes: [
                          {trait_type: "sectionName", value: sec.name},
                          {trait_type: "rowName", value: null},
                          {trait_type: "seatNum", value: x+1}
                      ]
                  }
              }
              count++;
          }
      }
      if(sec.type === "Assigned"){ 
          for(let row of sec.rows){
              for(let x = 0; x < row.seats; x++){
                  temp = {
                      ...temp,
                      [parseInt(count)]: {
                          image: "", 
                          description: "", 
                          date: "", 
                          name: "",
                          attributes: [
                              {trait_type: "sectionName", value: sec.name},
                              {trait_type: "rowName", value: row.name},
                              {trait_type: "seatNum", value: x+1}
                          ]
                      }
                  }
                  count++;
              }
          }
      }
    }
    localStorage.setItem('objStrData', temp);
    return JSON.stringify(temp, undefined, 4);
}
export default objectBuilder