// Dependecies
import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import LinearProgress from '@mui/material/LinearProgress';

// Components
import WalletButton from "../components/importWalletButton";
import {WalletCards} from "../components/nftCards";
import CurrencyBalance from "../components/currencyBalance";

// Hooks
import useWalletState from "../hooks/useWalletState";

const Wallet = () => {
    const axios = require('axios').default;
    const [loading, setLoading] = useState(true);
    const walletState = useWalletState();
    const [filter, setFilter] = useState('all');
    const [shouldClose, setShouldClose] = useState(false);
    const [viewOld, setViewOld] = useState(false);
    const [nfts, setNfts] = useState([
        {
            id: "",
            name: "",
            logo: "",
            creator: "",
            address: "...............",
            eventDate: "July 1st, 2022",
            metadata: [],
        }
    ]);
    const handleRadioBtnChange = (event) => {
        setFilter(event.target.value);
      };

    function nftsFiltered() {
        //console.log(nfts)
        if(filter === "active"){
            return nfts.filter((item) => new Date(item.eventDate) <= new Date())
        }else if(filter === "old"){
            return nfts.filter((item) => new Date(item.eventDate) > new Date())
        }else{
            return nfts
        }
        
    }
    // const handleCheckboxClick = (e)  =>  {
    //     if(e.target.id ===  "viewAll"){
    //         if(!viewAll){
    //             setViewActive(false);
    //             setViewOld(false);
    //         }if(viewAll){
    //             setViewActive(true);
    //             setViewOld(false);
    //         }
    //         setViewAll(!viewAll);
    //     }
    //     if(e.target.id ===  "viewActive"){
    //         if(!viewActive){
    //             setViewAll(false);
    //             setViewOld(false);
    //         }if(viewActive){
    //             setViewAll(true);
    //             setViewOld(false);
    //         }
    //         setViewActive(!viewActive);
    //     }
    //     if(e.target.id ===  "viewOld"){
    //         if(!viewOld){
    //             setViewAll(false);
    //             setViewActive(false);
    //         }if(viewOld){
    //             setViewAll(true);
    //             setViewActive(false);
    //         }
    //         setViewOld(!viewOld);
    //     }
    // }
    
    

    useEffect(() => {
        //axios.get(`https://api.testnet.klever.finance/v1.0/assets/kassets?owner=klv1pz2qmsd6tuta2dplfk5j8dt5wwapfhdasu78j5e8mlpuvvlf5frsyyfe0r&page=1&limit=10`)
        axios.get(`https://api.testnet.klever.finance/v1.0/address/${sessionStorage.getItem('walletAddress')}`)
        .then(function (res) {
            // handle success
            //console.log(res)
            if(Object.keys(res.data.data.account.assets).length){
                setNfts(res.data.data.account.assets)
            }else{
                setNfts([
                    {
                        id: "No Ticktet(s) found",
                        name: "Sorry :(",
                        logo: "https://image.shutterstock.com/image-vector/lost-items-line-vector-icon-600w-1436787446.jpg",
                        creator: "Omni",
                        eventDate: "07/01/2020",
                        metadata: [],
                    },
                ])
            }
        })
        .catch(function (error) {
            // handle error
            console.log(`Error: ${error}`);
        })
        .then(function () {
            // always executed
            setLoading(false)
        });
    }, [])
    function test() {
        console.log(walletState);
    }
    return (
        <>
            <Box
                sx={{
                    '& > :not(style)': { m: 1.5 },
                }}
            >
                <h1>Wallet</h1>
                <Box> 
                    <Paper variant="outlined" sx={{padding: .25 }}>
                        { sessionStorage.getItem('walletAddress') ? 
                            <h4><AccountBalanceWalletIcon/>{sessionStorage.getItem('walletAddress').slice(0,8) +'...'+sessionStorage.getItem('walletAddress').slice(-8)} <CurrencyBalance /></h4> : 
                            <WalletButton />
                        }
                    </Paper>
                </Box>
                <Box > 
                    {loading ? <LinearProgress color="secondary" /> :
                        <Paper className="walletNFTs" variant="outlined" sx={{padding: 1 }}>
                            <>  
                                <div>
                                    <FormControl>
                                        <FormLabel id="nfts-list-display-buttons">Ticket Filter:</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="nfts-list-display-buttons"
                                            name="row-radio-buttons-group"
                                            value={filter}
                                            onChange={handleRadioBtnChange}
                                        >
                                            <FormControlLabel value="all" control={<Radio />} label="All" />
                                            <FormControlLabel value="active" control={<Radio />} label="Active" />
                                            <FormControlLabel value="old" control={<Radio />} label="Old" />
                                        </RadioGroup>
                                    </FormControl>
                                    
                                </div>
                                { sessionStorage.getItem('walletAddress') ? 
                                    <Box sx={{ display: 'inline-flex', flexWrap: "wrap"}}><WalletCards data={nftsFiltered()} /></Box> : 
                                    <Typography textAlign="center" sx={{fontSize: 25, marginTop: 10, display: 'inline-flex'}}> "Wallet Not Found" </Typography>
                                }
                            </>
                        </Paper>
                    }
                </Box>
            </Box>
        </>
    );
  };
  
  export default Wallet;