import { useEffect, useRef, useState, createContext, useContext } from 'react';
import { Account, core } from '@klever/sdk';

interface ISdkContext {
  isLoaded(): Promise<boolean>;
  getAccount(): Account | null;
  setAccount(account: Account): void;
}
interface Props {
  children: React.ReactNode;
}

const SdkContext = createContext( {} as ISdkContext);

const SdkProvider: React.FC<Props> = ( {children} ) => {
  const [acc, setAcc] = useState<Account | null>(null);

  const values: ISdkContext = {
    isLoaded: () => core.isSDKLoaded(),
    getAccount: () => acc,
    setAccount: account => setAcc(account),
  };
  return <SdkContext.Provider value={values}>{children}</SdkContext.Provider>;
};
const useSdk = () => useContext(SdkContext);


const useDidUpdateEffect = (fn: Function, inputs: Array<any>): void => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }
    didMountRef.current = true;
  }, inputs);
};


export { SdkContext, SdkProvider, useSdk , useDidUpdateEffect };

