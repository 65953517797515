import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { white } from '@mui/material/colors';

function ObjDialog(props) {
  const { onClose, open, data, ticketInfo } = props;
  const [objText, SetObjText] = React.useState({})
  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };
  const exportToFile = () => {
    const element = document.createElement("a");
    //pass data from localStorage API to blob
    console.log(localStorage.getItem('objStrData'))
    const textFile = new Blob([[JSON.stringify(localStorage.getItem('objStrData'))], {type: 'text/plain'}]);
    element.href = URL.createObjectURL(textFile);
    element.download = "Omni_jsonHelper.txt";
    document.body.appendChild(element); 
    element.click();
  }
  const objectBuilder = () => {
      let count = 1;
      let temp = {
        image: ticketInfo.logo || "", 
        description: ticketInfo.description || "", 
        date: ticketInfo.eventTime || "", 
        location: ticketInfo.eventLocation || "",
        name: ticketInfo.name || "",
      }
      for (let sec of data) {
        if(sec.type === "Unassigned"){ 
            for(let x = 0; x < sec.seats; x++){
                temp = {
                    ...temp,
                    [parseInt(count)]: {
                        image: ticketInfo.logo || "", 
                        description: ticketInfo.description || "", 
                        date: ticketInfo.eventTime || "", 
                        name: ticketInfo.name || "",
                        attributes: [
                            {trait_type: "sectionName", value: sec.name},
                            {trait_type: "rowName", value: null},
                            {trait_type: "seatNum", value: x+1}
                        ]
                    }
                }
                count++;
            }
        }
        if(sec.type === "Assigned"){ 
            for(let row of sec.rows){
                for(let x = 0; x < row.seats; x++){
                    temp = {
                        ...temp,
                        [parseInt(count)]: {
                            image: "", 
                            description: "", 
                            date: "", 
                            name: "",
                            attributes: [
                                {trait_type: "sectionName", value: sec.name},
                                {trait_type: "rowName", value: row.name},
                                {trait_type: "seatNum", value: x+1}
                            ]
                        }
                    }
                    count++;
                }
            }
        }
      }
      localStorage.setItem('objStrData', temp);
      return JSON.stringify(temp, undefined, 4);
  }

  return (
    <Dialog 
        onClose={handleClose} 
        open={open} 
        fullWidth={true}
        maxWidth={"md"}
    >
      <DialogTitle>JSON Helper Output</DialogTitle>
      <Button onClick={exportToFile}>Export To File</Button>
      <Box
            sx={{
                p: 2,
                bgcolor: 'white',
                margin: "auto",
                overflow: "auto"
            }}
        >
            <pre>{objectBuilder()}</pre>
        </Box>
    </Dialog>
  );
}

ObjDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  ticketInfo: PropTypes.object.isRequired,
};

export default function ObjectDialog(props) {
  const { data, ticketInfo } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" onClick={handleClickOpen}>
        JSON Builder (BETA)
      </Button>
      <ObjDialog
        data={data}
        ticketInfo={ticketInfo}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
