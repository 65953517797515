
// Denepdencies
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import { Item, ItemSecondary } from "../theme";
import { useSdk } from '../hooks/KleverHook.tsx'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// Components
import ConfirmationDialog from '../components/confirmTransDialog';
import SellOrder from "../components/sellOrder";
import UpdateAsset from "../components/updateAsset";

// Utils
import { convertToURI } from "../utils";

// Hooks


const CreateMarket = () => {
    const sdk = useSdk();
    const isLoaded = sdk.isLoaded()
    const account = sdk.getAccount();
    const [toURI, setToURI] = useState(false);
    const [logo, setLogo] = useState("");
    const [uris, setUris] = useState("");
    const [meta, setMeta] = useState(``);

    const [payload, setPayload] = useState({
        name: "",
        referralAddress: "",
        referralPercentage: 1,
    });
    const [payloadMint, setPayloadMint] = useState({
        triggerType: 0,
        assetID: "",
        receiver: "",
        amount: 1,
    });
    const [marketplace, setMarkeplace] = useState("");
    const [message, setMessage] = useState("");
    const [rawTX, setRawTX] = useState(undefined)

    
    const handleButtonClick = async () => {
        if(isLoaded){
            setMessage("")
            let props = {
                autobroadcast: false,
                previousTX: undefined,
                //metadata: JSON.stringify(getAllSections())
            }
           await account.sendCreateMarketplace(payload, props)
            .then(function (response) {
                // handle success
                console.log(response);
                setRawTX(response);
            
                //setMessage("Success")
            })
            .catch(function (error) {
                // handle error
                console.log(`Error: ${error.message}`);
                setMessage(error.message)
            })
            .then(function () {
                // always executed
            });
        }
    }
    const handleButtonClickMint = async () => {
        if(isLoaded){
            setMessage("")
            let payload = {
                triggerType: 0,
                assetID: payloadMint.assetID,
                receiver: payloadMint.receiver,
                amount: payloadMint.amount,
            }
            let previousTX = undefined;
            let props = {
                autobroadcast: false,
                previousTX: previousTX,
                //metadata: JSON.stringify({where: "Test", what: "Test"})
            }

            // klv1vzh5u9n7u9hn99tyc3l6t9e3yan9k93fs3u30c9hykncthrxvzssx748lw
            console.log(payload);
           await account.sendAssetTrigger(payload, props)
            .then(async function (response) {
                // handle success
                //console.log(response);
                previousTX = response;
                if(logo.length){
                    if(toURI){
                        setLogo(convertToURI(payloadMint.logo, toURI))
                    }
                    previousTX = await account.sendAssetTrigger({
                        triggerType: 10,
                        assetID: payloadMint.assetID,
                        logo: logo,
                    }, {autobroadcast: false, previousTX: previousTX})
                }
                if(uris.length){
                    previousTX = await account.sendAssetTrigger({
                        triggerType: 11,
                        assetID: payloadMint.assetID,
                        uris: uris,
                    }, {autobroadcast: false, previousTX: previousTX})
                }
                // if(meta.length){
                //     previousTX = await account.sendAssetTrigger({
                //         triggerType: 8,
                //         assetID: payloadMint.assetID,
                //         logo: logo,
                //     }, {autobroadcast: false, metadata: JSON.stringify(meta), previousTX: previousTX})
                // }
                console.log(previousTX)
                setRawTX(previousTX);

            })
            .catch(function (error) {
                // handle error
                console.log(`Error: ${error.message}`);
                setMessage(error.message)
            })
            .then(function () {
                // always executed
            });
        }
    }
    
    return (
        <>
            <h1>Update Assets</h1>
            <Box>
                <Item>
                    <Box className="generalInfo"
                        // sx={{
                        //     width: 700,
                        //     maxWidth: '100%',
                        //     display: 'flex',
                        //     flexWrap: 'wrap',
                        //   }}
                        sx={{
                            '& > :not(style)': { m: 1.5 },
                        }}
                    >
                        <h2>Create Marketplace (For Demo)</h2>
                        <TextField
                            required
                            variant="standard"
                            id="name"
                            label="Marketplace Name:"
                            value={payload.name}
                            onChange={(e) => setPayload({ ...payload, name: e.target.value })}
                        />
                        <TextField
                            required
                            variant="standard"
                            id="refPercentage"
                            label="Referral Percentage: "
                            value={payload.referralPercentage}
                            onChange={(e) => setPayload({ ...payload, referralPercentage: e.target.value })}
                        />
                        <TextField
                            sx={{
                                '& > :not(style)': { width: '40ch' },
                            }}
                            required
                            variant="standard"
                            id="refWallet"
                            label="referral Wallet Address (KLV):"
                            value={payload.referralAddress}
                            onChange={(e) => setPayload({ ...payload, referralAddress: e.target.value })}
                        />
                    </Box>
                    <Button variant="contained" name="btnCreateMarket" onClick={handleButtonClick}>
                        Create Marketplace
                    </Button>
                    <span>{message}</span>
                </Item>
            </Box>
            <Box>
                <Item>
                    <Box className="mintAsset"
                        // sx={{
                        //     width: 700,
                        //     maxWidth: '100%',
                        //     display: 'flex',
                        //     flexWrap: 'wrap',
                        //   }}
                        sx={{
                            '& > :not(style)': { m: 1.5 },
                        }}
                    >
                        <h2>Mint Assets</h2>
                        <TextField
                            disabled
                            variant="standard"
                            id="triggerType"
                            label="triggerType:"
                            value={payloadMint.triggerType}
                            onChange={(e) => setPayloadMint({ ...payloadMint, triggerType: e.target.value })}
                        />
                        <TextField
                            required
                            variant="standard"
                            id="assetID"
                            label="assetID:"
                            value={payloadMint.assetID}
                            onChange={(e) => setPayloadMint({ ...payloadMint, assetID: e.target.value })}
                        />
                        <TextField
                            required
                            type="number"
                            variant="standard"
                            id="amount"
                            label="Amount:"
                            value={payloadMint.amount}
                            onChange={(e) => setPayloadMint({ ...payloadMint, amount: parseInt(e.target.value) })}
                        />
                        <TextField
                            required
                            variant="standard"
                            id="uris"
                            label="URIs:"
                            value={uris}
                            onChange={(e) => setUris(e.target.value )}
                        />
                        <TextField
                            sx={{
                                '& > :not(style)': { width: '40ch' },
                            }}
                            required
                            variant="standard"
                            id="logo"
                            label="Logo:"
                            value={logo}
                            onChange={(e) => setLogo(e.target.value)}
                        />
                        <FormControlLabel labelPlacement="start" control={
                            <Checkbox
                            checked={toURI}
                            onChange={ (e) => setToURI(e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="URL to URI?" />
                        <TextField
                            sx={{
                                '& > :not(style)': { width: '40ch' },
                            }}
                            required
                            variant="standard"
                            id="receiver"
                            label="Receiver Wallet Address (KLV):"
                            value={payloadMint.receiver}
                            onChange={(e) => setPayloadMint({ ...payloadMint, receiver: e.target.value })}
                        />
                        <TextField
                            sx={{
                                '& > :not(style)': { width: '40ch' },
                            }}
                            required
                            variant="standard"
                            id="meta"
                            label="Metadata:"
                            value={meta}
                            onChange={(e) => setMeta(e.target.value)}
                        />
                    </Box>
                    <Button variant="contained" name="btnCreateMarket" onClick={handleButtonClickMint}>
                        Mint Asset
                    </Button>
                    <span>{message}</span>
                </Item>
            </Box>
            <ConfirmationDialog data={rawTX}/>
            <UpdateAsset />
            <SellOrder />
        </>
    );
};

export default CreateMarket;