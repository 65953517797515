import { useEffect, useState } from "react";

import SlideShow from "../components/slideShow";
import Box from '@mui/material/Box';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();


const Home = () => {
    
    return (
        <>
            <h1 >NFT Ticket KAPP</h1>
            <h3>KleverChain Data</h3>
            <SlideShow 
                heading={"Block Info "} 
                url={'https://api.mainnet.klever.finance/v1.0/block/list?limit=5&page=1'} 
                width={150} 
            />
           
            <>
                <div data-aos="fade-left" data-aos-duration="8000">
                    <h2>What is this?</h2>
                    <p>Onmi and RudeMining is developing the next phase distributing tickets as NFTs to events such as concerts, sports events, private events, and more!

                    </p>
                </div>
                
                <div data-aos="fade-right" data-aos-duration="8000">
                    <h2>Why would I want to create tickets as NFTs</h2>
                    <p>For a long time, there has been issues with tickets being duplicated, printed on papper and lost, stolen, scalpers, among others.
                        Now with the power of Klever's Blockchain, tickets for for any kind of event can be created, bought, sold, and resold all in one place via 
                        the market place. See live seating updated and statics for your event all built in via the blockchain. Also, for the creator of the event,
                        there is a built in royalty mechanism which means when ever a ticket is bought and resold or transfered, the creator will receive payment 
                        automatically back in their wallet!
                    </p>
                </div>
                <div data-aos="fade-left" data-aos-duration="8000">
                    <h2 >Why would I want a ticket as a NFT</h2>
                    <p>Having your ticket as digitial asset means you are the sole owner of that ticket and no one else and can replicate it. You can transfer is easily
                        with your family and friends with a push of a button! Can't make the sold out event on short notice and can't find anyone to buy your ticket?
                        No problem! With these tickets you can list them back on the market place for price you set open to the public and not just limited to your cicle.
                    </p>
                </div>
                
            </>
            
            
        </>
    );
  };
  
  export default Home;