// Denepdencies
import { useState } from 'react';
import { useSdk } from '../hooks/KleverHook.tsx';
import Button from '@mui/material/Button';

// Components
import ConfirmationDialog from './confirmTransDialog';

// Utils
import { convertPrecision } from '../utils';

// Hooks

// tmp for test
import { Account } from "@klever/sdk";



const CreateAsset = ({payload, general, section}) => {
    const sdk = useSdk();
    const isLoaded = sdk.isLoaded();
    const account = sdk.getAccount();
    const [rawTX, setRawTX] = useState(undefined)

    const getAllSections = () => {
        return [...general, ...section]
    }

    const creatNFTs = async () => {
        let props = {
            autobroadcast: false,
            previousTX: null,
            metadata: JSON.stringify(getAllSections())
        }
        if(isLoaded){
            console.log(payload);
            const rawTransaction = await account.sendCreateAsset(payload, props)
            setRawTX(rawTransaction);
        }
        

        // Hackathon Test Accouunt
        // const axios = require('axios').default;
        // const account2 = new Account("klv1jmu37tpra7fkxcfpkfulj9fg56gt4czew9rkqzjmd9w3l8rhvfyq46xzwd", "192a116e3801953d92736b5d45aacb3c4846c4a2ebe7c2e08218df9f48caa7fd");
        // console.log(account2);
        // await account2.getBalance()
        // .then(function (response) {
        //     // handle success
        //     console.log(response);
        // })
        // console.log(payload);


        // console.log(account);
        // await account.getBalance()
        // .then(function (response) {
        //     // handle success
        //     console.log(ConvertAsset(response,6));
        // })
        // await account.sendCreateAsset(payload())
        
        // 
        // account.sendTransfer()
        // axios.get('https://api.testnet.klever.finance/v1.0/address/topsenders?page=1&limit=10')
        // .then(function (response) {
        //     // handle success
        //     console.log(response.data.data);
        // })
        // .catch(function (error) {
        //     // handle error
        //     console.log(`Error: ${error}`);
        // })
        // .then(function () {
        //     // always executed
        // });
    }
    
    return (
        <div>
            <Button variant="contained"  onClick={() => creatNFTs()}>Create NFTs </Button>
            <ConfirmationDialog data={rawTX}/>
        </div>
    );

}

export default CreateAsset