// Dependencies
import * as React from 'react';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

// Componets
import { DetailedCards } from './nftCards';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CardBalanceDialog({open, setOpen, assetId}) {
    const axios = require('axios').default;
    const [nftsFromAPI, setNftsFromAPI] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
      if(assetId){
        axios.get(`https://api.testnet.klever.finance/v1.0/address/${sessionStorage.getItem('walletAddress')}/collection/${assetId}?limit=100`)
        .then(function (res) {
            // handle success
            //console.log(res.data.data.collection)
            setNftsFromAPI(res.data.data.collection)
        })
        .catch(function (error) {
            // handle error
            console.log(`Error: ${error}`);
        })
        .then(function () {
            // always executed
        });
      }
    }, [assetId])

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {nftsFromAPI[0] ? nftsFromAPI[0].assetName : "..."}
            </Typography>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Total: {nftsFromAPI.length}
            </Typography>
          </Toolbar>
        </AppBar>
        <List >
          <ListItem button>
            <ListItemText primary={assetId} secondary={"Total Tickets:" + nftsFromAPI.length} />
          </ListItem>
          <Divider />
          
        </List>
        <div className='cardlist'>
            <DetailedCards data={nftsFromAPI} sx={{ display: 'block' }} />
        </div>
      </Dialog>
    </div>
  );
}
