import { useEffect, useState } from "react";
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const SlideShow =  ({heading, url , width}) => {
    const [blockData, setBlockData] = useState([
        {
            nonce: <Skeleton count={1}/>,
            epoch: <Skeleton count={1}/>,
            txCount: <Skeleton count={1}/>,
            hash: '......',
        }
    ])
    const axios = require('axios').default;

    useEffect(() => {
        axios.get(url)
        .then(function (res) {
            // handle success
            setBlockData(res.data.data.blocks)
        })
        .catch(function (error) {
            // handle error
            console.log(`Error: ${error}`);
        })
        .then(function () {
            // always executed
        });
    }, [])
    
    let slideIndex = 0;
    
     function showSlides() {
      let i;
      let slides = document.getElementsByClassName("mySlides");
      let dots = document.getElementsByClassName("dot");
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";  
      }
      
      if (slideIndex > slides.length - 1) {slideIndex = 0}    
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      if(slides.length && dots.length){
        //TOOD: check consistency
        //console.log(slideIndex)
        slides[slideIndex].style.display = "block";  
        dots[slideIndex].className += " active";
      }
      setTimeout(showSlides, 3000); // Change image every 3 seconds
      slideIndex++;
    }

    showSlides();
  return (
    <Box sx={{ maxWidth: width, flexGrow: 1 }}>
        <h2>{heading}</h2>
        <div className="slideshow-container">
            {blockData.map((item, index) => {
                return (
                    <Box className='mySlides' key={index} sx={{ minWidth: 275 }}>
                        <Card variant="outlined">
                            <React.Fragment>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Block Number
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {item.nonce}
                                    </Typography>
                                    
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        epoch   
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {item.epoch}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        txCount   
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {item.txCount}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href=`https://testnet.kleverscan.org/block/${item.nonce}`;
                                        }} size="small"> Hash: {item.hash.slice(0,5)}....{item.hash.slice(-5)}
                                    </Button>
                                </CardActions>
                            </React.Fragment>
                        </Card>
                    </Box>
                );
            })}
        <br />
        <>
            {blockData.map((item, index) => {
                return (
                    <span key={index} className="dot"></span>
                );
            })}
        </>
        </div>
    </Box>
  );
}

export default SlideShow;
