import { createTheme, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    //textAlign: 'center',
    padding: '5px',
    margin: '5px',
    color: theme.palette.text.primary,
    maxWidth: '750px',
    //height: 60,
    //lineHeight: '60px',
}));

const ItemSecondary = styled(Paper)(({ theme }) => ({
    maxWidth: '700px',
}));

const mainTheme = createTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#b50808',
      },
      secondary: {
        main: '#f90422',
      },
      text: {
        primary: '#000000',
      },
      background: {
        //default: '#201e1e',
        paper: '#e3e3e3',
      },
    },
  });

export {mainTheme, Item, ItemSecondary}