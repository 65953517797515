// Dependency
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';

// Hooks
import { useSdk } from '../hooks/KleverHook.tsx'

// Utils
import {convertPrecision} from '../utils/';

const CurrencyBalance = ({name, type}) => {
    const axios = require('axios').default;
    const [balance, setBalance] = useState(0)
    
    useEffect(() => {
        if(sessionStorage.getItem('walletAddress')){
          axios.get(`https://api.testnet.klever.finance/v1.0/address/${sessionStorage.getItem('walletAddress')}`)
          .then(function (res) {
              // handle success
              //console.log(res.data.data.account.balance)
              setBalance(res.data.data.account.balance)
          })
          .catch(function (error) {
              // handle error
              console.log(`Error: ${error}`);
          })
          .then(function () {
              // always executed
          });
        }
      }, [name])
    
    return (
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {type ? type : "KLV"}: {convertPrecision(balance, 6, "real")}
        </Typography>
    );

}

export default CurrencyBalance