const getAssetInfo = async (assetId) => {
    const axios = require('axios').default;
    let data = {};
    
    await axios.get(`https://api.testnet.klever.finance/assets/${assetId}`)
    .then(function (res) {
        // handle success
        data = res.data.data.asset;
        console.log(res.data.data.asset);
    })
    .catch(function (error) {
        // handle error
        console.log(`Error: ${error}`);
    })
    .then(function () {
        // always executed
    });
    return data
}
export default getAssetInfo