import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from './pages/Layout';
import Home from './pages/Home'
import Market from './pages/Market'
import Event from './pages/Event'
import CreateNFTs from './pages/CreateNFTs'
import Wallet from './pages/Wallet';
import NoPage from './pages/NoPage';
import SellNFTs from './pages/SellNFT';
// Temp for demo
import CreateMarket from './pages/CreateMarketplace';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout /> }>
              <Route index element={<Home />} />
              <Route path="market" element={<Market />} />
              <Route path="market/sell/:assetId/:nftId" element={<SellNFTs />} />
              <Route path="market/sell/" element={<SellNFTs />} />
              <Route path="event/:assetId" element={<Event />} />
              <Route path="createNFTs" element={<CreateNFTs />} />
              <Route path="wallet" element={<Wallet />} />
              <Route path="updateNfts" element={<CreateMarket />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
